/* Basic reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}

/* Container for the agency details */
.agency-detail-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header styles */
.agency-detail-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.agency-detail-header img {
    max-width: 150px;
    border-radius: 10px;
    margin-right: 20px;
}

.agency-detail-header h2 {
    font-size: 28px;
    color: #333;
}

/* Agency description styles */
.agency-description {
    margin-bottom: 20px;
    line-height: 1.6;
    color: #555;
}

/* Additional information */
.agency-info {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}

.agency-info li {
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
}

.agency-info li span {
    font-weight: bold;
}

/* Back to service button */
.back-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.back-button:hover {
    background-color: #0056b3;
}
.agency-photo {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
}
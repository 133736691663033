/* StarRating.css */
.star-rating {
  display: inline-flex;
  direction: ltr;
}

.star {
  cursor: pointer;
  font-size: 2rem;
  margin: 5px;
  transition: color 0.2s ease; /* Smooth color transition on hover and click */
  color: #e4e5e9; /* Default color for unselected stars */
}

input[type="radio"] {
  display: none; /* Hide the input field */
}

/* General styles for the service-users-table */
.service-users-table {
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    text-align: center;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Table styles */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 30px;
  }
  
  thead {
    background-color: #f4f4f4;
    border-bottom: 2px solid #ddd;
  }
  
  thead th {
    padding: 12px;
    text-align: left;
    font-size: 16px;
    color: #333;
  }
  
  tbody td {
    padding: 10px;
    font-size: 14px;
    color: #555;
    border-bottom: 1px solid #ddd;
  }
  
  /* Styling for service name row */
  .service-row {
    background-color: #f9f9f9;
    border-top: 2px solid #ddd;
  }
  
  .service-name {
    font-weight: bold;
    font-size: 18px;
    color: #222;
    padding: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    h2 {
      font-size: 20px;
    }
    
    thead th {
      font-size: 14px;
      padding: 8px;
    }
  
    tbody td {
      font-size: 12px;
      padding: 8px;
    }
  
    .service-name {
      font-size: 16px;
      padding: 8px;
    }
  }
  
  @media (max-width: 480px) {
    table, thead, tbody, th, td, tr {
      display: block;
    }
  
    thead {
      display: none;
    }
  
    tbody td {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ddd;
    }
  
    tbody td::before {
      content: attr(data-label);
      font-weight: bold;
      text-transform: capitalize;
      color: #333;
      flex-basis: 40%;
    }
  
    .service-row td {
      font-size: 16px;
      text-align: center;
    }
  
    .service-name {
      font-size: 16px;
      padding: 10px;
      background-color: #f4f4f4;
    }
  }
  
/* src/components/Navbar.css */
.navbar {
    background: rgba(255, 255, 255, 0.9); /* Semi-transparent white background */
    backdrop-filter: blur(10px); /* Blur effect for a glassy look */
    border-bottom: 1px solid rgba(0, 0, 0, 0.2); /* Subtle border */
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow for depth */
    border-radius: 8px; /* Rounded corners */
    margin: 0 10px; /* Margin to ensure it doesn't touch the edges */
    position: relative; /* For positioning the menu icon */
    z-index: 1000; /* Ensure this is a high value */
}

.navbar h1 {
    margin: 0;
    font-size: 24px;
    color: #000; /* Black color for visibility */
    font-family: 'Arial', sans-serif;
    font-weight: 700; /* Bold font weight */
    padding-left: 10px;
}

.navbar-menu-icon {
    display: none; /* Hidden by default */
    flex-direction: column;
    cursor: pointer;
    position: absolute;
    right: 20px; /* Align to the right */
}

.navbar-menu-icon .bar {
    width: 25px;
    height: 3px;
    background-color: #000;
    margin: 4px 0;
    transition: 0.3s;
}

/* Navbar links styles */
.navbar-links {
    display: flex;
    gap: 20px; /* Increased gap for better spacing */
}

.navbar-links a,
.navbar-links button {
    color: #000; /* Black color for visibility */
    font-size: 16px;
    border: none;
    background: none;
    cursor: pointer;
    text-decoration: none;
    padding: 8px 12px; /* Padding for better clickability */
    border-radius: 5px; /* Rounded corners */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.navbar-links a {
    font-weight: 500; /* Medium font weight for links */
}

.navbar-links a:hover,
.navbar-links button:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Subtle background on hover */
    color: #333; /* Darker color for better contrast on hover */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .navbar {
        padding: 15px 10px; /* Reduced padding for medium screens */
    }
    
    .navbar h1 {
        font-size: 22px; /* Slightly smaller font size */
    }
    
    .navbar-links {
        gap: 15px; /* Reduced gap for medium screens */
    }
}

@media (max-width: 768px) {
    .navbar {
        padding: 15px 10px; /* Further reduced padding for smaller screens */
        flex-direction: row; /* Ensure horizontal layout for heading and icon */
        align-items: center; /* Center align items vertically */
    }
    
    .navbar h1 {
        font-size: 20px; /* Smaller font size */
        margin: 0; /* Remove margin for better alignment */
        flex: 1; /* Take up available space */
    }
    
    .navbar-menu-icon {
        display: flex; /* Show hamburger menu icon */
        z-index: 1000; /* Ensure it is on top */
    }

    .navbar-links {
        width: 100%; /* Full width for links container */
        flex-direction: column; /* Stack links vertically */
        align-items: center; /* Center items horizontally */
        gap: 10px; /* Reduced gap for smaller screens */
        display: none; /* Hide links by default */
        position: absolute; /* Position it below the navbar */
        top: 60px; /* Adjust based on the navbar height */
        left: 0;
        background: rgba(255, 255, 255, 0.9); /* Match navbar background */
        border-radius: 0 0 8px 8px; /* Rounded corners at the bottom */
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Same shadow effect */
        padding: 15px 0; /* Padding around the links */
        z-index: 1000; /* Ensure it is on top */
    }

    .navbar-links.open {
        display: flex; /* Show links when the menu is open */
    }
}

.navbar-dropdown {
    position: relative;
    display: inline-block;
    padding: 8px 12px;
  }
  
  .navbar-dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    border-radius: 5px;
  }
  
  .navbar-dropdown:hover .navbar-dropdown-content {
    display: block;
  }
  
  .member-item {
    padding: 8px 0;
    color: #333;
  }
  
  .member-item:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }
  

  /* Logo styling */
.navbar-logo {
    display: flex;
    align-items: center;
    margin-right: auto; /* Align logo to the left */
}

.navbar-logo img {
    height: 40px; /* Adjust the height to fit within the navbar */
    width: auto; /* Maintain the aspect ratio */
    display: block; /* Ensures no extra space around the image */
}

/* For smaller screens */
@media (max-width: 768px) {
    .navbar-logo img {
        height: 35px; /* Slightly smaller on mobile for better spacing */
    }
}


/* Styles for the dropdown menu under "Reviews" */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-button {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #000; 
    padding: 8px 12px;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.dropdown-content {
    display: none; /* Initially hide the dropdown */
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent white background */
    min-width: 220px; /* Increased minimum width to make dropdown wider */
    max-width: 300px; /* Set a maximum width to control the size */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Floating effect */
    z-index: 1;
    border-radius: 5px;
    padding: 10px 0; /* Padding around the links */
    top: 100%; 
    max-height: 300px; /* Set a longer maximum height for the dropdown */
    overflow-y: auto; /* Enable vertical scrolling when content exceeds max height */
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.dropdown-content a {
    color: #000; 
    padding: 12px 20px; /* Increased padding for better spacing */
    text-decoration: none;
    display: block; /* Make each link take up full width */
    font-size: 16px; /* Adjust font size for readability */
    transition: background-color 0.3s ease;
}

.dropdown-content a:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: #333;
}

/* Show the dropdown content when the dropdown is hovered (for desktop) */
.dropdown:hover .dropdown-content {
    display: block; 
}

/* Mobile-friendly: Show the dropdown content when button is clicked */
.dropdown.open .dropdown-content {
    display: block;
}

/* Ensure dropdown is full-width on mobile screens */
@media (max-width: 768px) {
    .dropdown-content {
        min-width: 100%; /* Dropdown takes full width of the screen */
        left: 0; /* Align it to the left edge of the screen */
        max-width: none; /* Remove the max-width on mobile for full-screen use */
    }
}

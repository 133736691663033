/* src/components/ServiceSelectionPopup.css */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
  max-width: 600px;
  width: 100%;
}

.popup-content h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.service-selection-grid {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.service-selection-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.service-selection-row span {
  font-size: 1.2em;
  color: #555;
  flex: 2;
}

.service-selection-row select {
  padding: 8px 10px;
  font-size: 1em;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  flex: 3;
}

.service-selection-row input[type="checkbox"] {
  flex: 0;
  width: 20px;
  height: 20px;
  margin-left: 15px;
}

.vendor-details {
  margin-top: 10px;
  font-size: 0.9em;
  color: #666;
  flex: 3;
}

.vendor-details p {
  margin: 2px 0;
}

.popup-content button {
  background-color: #007bff;
  color: #fff;
  padding: 12px 20px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.popup-content button:hover {
  background-color: #0056b3;
}

.popup-content button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .popup-content {
    padding: 20px;
    width: 90%; /* Make the popup take more width on smaller screens */
    max-width: 100%;
  }

  .popup-content h2 {
    font-size: 1.5em;
  }

  .service-selection-row {
    flex-direction: column; /* Stack the content vertically on smaller screens */
    align-items: flex-start; /* Align items to the start */
  }

  .service-selection-row span {
    margin-bottom: 10px;
  }

  .service-selection-row select {
    width: 100%; /* Make the select box take full width */
  }

  .service-selection-row input[type="checkbox"] {
    margin-top: 10px;
    margin-left: 0; /* Reset the margin for better alignment */
  }

  .vendor-details {
    margin-top: 15px;
    font-size: 0.85em; /* Slightly smaller text for vendor details */
  }
}

@media (max-width: 480px) {
  .popup-content {
    padding: 15px;
  }

  .popup-content h2 {
    font-size: 1.2em;
  }

  .service-selection-row {
    padding: 10px;
  }

  .service-selection-row span {
    font-size: 1em;
  }

  .service-selection-row select {
    padding: 6px 8px;
  }

  .popup-content button {
    padding: 10px 15px;
    font-size: 0.9em;
  }
}
.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: #333;
}
.boss{
  display: flex;
  justify-content: end;
}

.close-icon:hover {
  color: #000;
}
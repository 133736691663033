.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    position: relative; /* Important for positioning the close icon */
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    max-height: 80%;
    overflow-y: auto;
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
}
.box{
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: end;
    align-items: center;
}

.popup-content h2 {
    margin-top: 0;
}

.popup-content p {
    font-size: 16px;
    line-height: 1.5;
    color: black;
    text-align: justify;
    text-decoration: solid;
}

ul {
    padding-left: 20px;
    margin: 10px 0;
}

.service-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
    padding: 20px;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .service-card img {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #eee;
  }
  
  .service-card h2 {
    font-size: 20px;
    margin: 15px 0;
  }
  
  .service-card a {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 20px;
    color: #007bff;
    text-decoration: none;
    border: 1px solid #007bff;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .service-card a:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  @media (max-width: 768px) {
    .service-card h2 {
      font-size: 18px;
    }
    
    .service-card a {
      padding: 8px 16px;
    }
  }
  
  @media (max-width: 480px) {
    .service-card h2 {
      font-size: 16px;
    }
    
    .service-card a {
      padding: 6px 12px;
    }
  }
  
/* src/pages/Home.css */

.home {
  padding: 20px;
  text-align: center;
}

.apartment-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.apartment-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.apartment-card img {
  max-width: 100%;
  border-radius: 8px;
  height: 350px;
  object-fit: cover;
}

.apartment-card h2 {
  margin: 10px 0;
  font-size: 1.5rem;
}

.apartment-card p {
  color: #666;
}


.header-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the logo and heading horizontally */
  margin-bottom: 10px; /* Space between the header and the paragraph */
}

.home-logo {
  height: 90px; /* Increase the size of the logo */
  width: auto; /* Keep the aspect ratio */
}

.home h1 {
  font-size: 28px;
  margin: 0;
}

.intro-text {
  font-size: 18px;
  color: #555;
  margin: 0 auto 20px; /* Center the paragraph and add space below */
  line-height: 1.6;
  text-align: center; /* Center-align the text */
  max-width: 800px; /* Limit the width of the paragraph */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .header-container {
    flex-direction: column; /* Stack the logo and heading vertically */
    text-align: center; /* Ensure everything is centered */
  }

  .home-logo {
    height: 70px; /* Reduce logo size for smaller screens */
    margin-bottom: 10px; /* Add space below the logo */
  }

  .home h1 {
    font-size: 24px; /* Reduce heading size for smaller screens */
  }

  .intro-text {
    font-size: 16px; /* Slightly smaller text for better fit */
    max-width: 90%; /* Allow the paragraph to take more space on small screens */
  }
}

@media (max-width: 480px) {
  .home-logo {
    height: 60px; /* Further reduce logo size for mobile screens */
  }

  .home h1 {
    font-size: 22px; /* Further reduce heading size */
  }

  .intro-text {
    font-size: 15px; /* Smaller text for better readability on mobile */
    max-width: 100%; /* Use the full width of the screen */
  }
}


.welcome-message {
  font-size: 24px;
  font-weight: 600px;
  color: hsl(23, 97%, 46%);
  margin-bottom: 20px;
  text-align: left; /* Align text to the left */
  padding-left: 20px; /* Add some padding for better alignment */
  display: flex;
  align-items: center; /* Vertically center the text */
}

.typed-text {
  font-weight: bold;
  margin-left: 5px; /* Space between "Welcome," and the animated text */
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.home-logo {
  height: 90px;
  width: auto;
}

.home h1 {
  font-size: 28px;
  margin: 0;
}

.intro-text {
  font-size: 18px;
  color: #555;
  margin: 0 auto 20px;
  line-height: 1.6;
  text-align: center;
  max-width: 800px;
}

@media (max-width: 768px) {
  .welcome-message {
    font-size: 20px;
    padding-left: 10px;
  }

  .typed-text {
    font-size: 20px;
  }
}

/* src/pages/SignUp.css */
.auth-form {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 50px auto;
  padding: 30px;
  text-align: center;
}

.auth-form h1 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

.auth-form input,
.auth-form select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

/* Dropdown specific styles */
.auth-form select {
  appearance: none; /* Remove default dropdown arrow */
  background-color: #fff;
  cursor: pointer;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" stroke="%23000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><path d="M6 9l6 6 6-6"/></svg>'); 
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
}

.auth-form button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.auth-form button:hover {
  background-color: #0056b3;
}

.auth-form p {
  margin-top: 20px;
}

.auth-form a {
  color: #007bff;
  text-decoration: none;
}

.auth-form a:hover {
  text-decoration: underline;
}

.session-expired-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
  }
  
  .session-expired-popup p {
    margin: 0 0 10px;
    font-size: 16px;
    color: #333;
  }
  
  .session-expired-popup button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .session-expired-popup button:hover {
    background-color: #0056b3;
  }
  
.members-page {
    text-align: center;
    padding: 40px 20px;
    background-color: #f0f4f8;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .members-page h1 {
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 30px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .members-list {
    width: 100%;
    max-width: 800px;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .member-item {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    text-align: left;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .member-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  }
  
  .member-item h3 {
    font-size: 1.25rem;
    color: #3498db;
    margin-bottom: 10px;
    font-weight: 600;
  }
  
  .member-item p {
    font-size: 1rem;
    color: #34495e;
    line-height: 1.5;
  }
  
  .back-to-home-button {
    display: inline-block;
    margin: 20px auto;
    padding: 12px 24px;
    background-color: #3498db;
    color: #ffffff;
    text-decoration: none;
    border-radius: 30px;
    transition: all 0.3s ease;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .back-to-home-button:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  
  @media (max-width: 768px) {
    .members-page h1 {
      font-size: 2rem;
    }
  
    .members-list {
      grid-template-columns: 1fr;
    }
  
    .member-item {
      padding: 15px;
    }
  
    .member-item h3 {
      font-size: 1.1rem;
    }
  
    .member-item p {
      font-size: 0.9rem;
    }
  
    .back-to-home-button {
      padding: 10px 20px;
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .members-page {
      padding: 30px 15px;
    }
  
    .members-page h1 {
      font-size: 1.75rem;
    }
  
    .back-to-home-button {
      width: 100%;
    }
  }

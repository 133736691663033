/* Overall Page Styling */
.auth-form {
    background-color: #f9f9f9;
    padding: 40px;
    max-width: 400px;
    margin: 80px auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .auth-form h1 {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 600;
  }
  
  .auth-form form {
    display: flex;
    flex-direction: column;
  }
  
  .auth-form input {
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .auth-form input:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
    outline: none;
  }
  
  .auth-form button {
    padding: 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .auth-form button:hover {
    background-color: #0056b3;
  }
  
  .auth-form p {
    margin-top: 20px;
    font-size: 14px;
    color: #666;
    text-align: center;
  }
  
  .auth-form a {
    color: #007bff;
    text-decoration: none;
    font-weight: 500;
  }
  
  .auth-form a:hover {
    text-decoration: underline;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 480px) {
    .auth-form {
      padding: 20px;
      margin: 40px auto;
    }
  
    .auth-form h1 {
      font-size: 24px;
    }
  
    .auth-form input, .auth-form button {
      font-size: 14px;
    }
  }
  
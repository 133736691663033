.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.footer-company-info {
  text-align: left;
}

.footer-company-info h4 {
  margin-bottom: 10px;
}

.footer-company-info ul {
  list-style: none;
  padding: 0;
}

.footer-company-info ul li {
  margin: 5px 0;
  cursor: pointer;
}

.disclaimer-link {
  cursor: pointer;
  color: #fafafa;
  text-decoration: solid;
}

.disclaimer-link:hover {
  color: #000103;
}

p {
  margin: 0;
  font-size: 14px;
}

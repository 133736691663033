.service-selection {
  padding: 40px 20px;
  text-align: center;
}

.service-selection h1 {
  font-size: 28px;
  margin-bottom: 20px;
}

.services-available {
  margin-bottom: 30px;
}

.services-available h2 {
  font-size: 22px;
  margin-bottom: 20px;
}

/* Button styling */
.services-available button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.services-available button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Button responsiveness */
@media (max-width: 768px) {
  .services-available button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .services-available button {
    width: 100%; /* Full width buttons on small screens */
    margin: 8px 0;
    font-size: 14px;
  }
}

/* Service grid styling */
.service-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

@media (max-width: 768px) {
  .service-grid {
    flex-direction: column;
    gap: 15px;
  }
}

.service-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* Reduced gap between the cards */
  justify-content: start; /* Center the grid items */
}

.service-card {
  flex-shrink: 1;
  flex-basis: calc(25% - 15px); /* Adjusted for smaller cards */
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Slightly deeper shadow for emphasis */
  overflow: hidden;
  text-align: center;
  padding: 15px; /* Reduced padding inside the cards */
  transition: transform 0.3s, box-shadow 0.3s;
}

.service-card img {
  width: 100%;
  height: 350px; /* Reduced height for a more compact look */
  object-fit: cover; /* Maintain aspect ratio while filling the area */
  border-bottom: 1px solid #eee;
}

.service-card h2 {
  font-size: 18px; /* Slightly smaller heading */
  margin: 10px 0; /* Reduced margin for a tighter layout */
}

.service-card a {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 16px; /* Slightly reduced padding */
  color: #007bff;
  text-decoration: none;
  border: 1px solid #007bff;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.service-card a:hover {
  background-color: #007bff;
  color: #fff;
}

@media (max-width: 1200px) {
  .service-card {
    flex: 1 1 calc(33.333% - 15px); /* Three cards per row */
  }
}

@media (max-width: 768px) {
  .service-card {
    flex: 1 1 calc(50% - 15px); /* Two cards per row */
    height: 100%; /* Adjust height for better spacing */
  }

  .service-card img {
    height: 200px; /* Further reduced height for smaller screens */
  }
}

@media (max-width: 480px) {
  .service-card {
    flex: 1 1 100%; /* Full width for each card */
    height: 100%;
  }

  .service-card img {
    height: 180px; /* Reduced height for mobile view */
  }
}


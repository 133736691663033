.review-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #f8f9fa;
    min-height: 100vh;
    position: relative;
    z-index: 1; /* Lower than the navbar */
}

.review-page h1 {
    text-align: center;
    color: #2c3e50;
    margin-bottom: 30px;
    font-size: 2.5rem;
    font-weight: 700;
}

.agency-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 25px;
    margin-bottom: 40px;
    position: relative;
    z-index: 2; /* Higher than the page, but lower than the navbar */
}

.agency-card {
    background-color: #fff;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
}

.agency-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.agency-card.selected {
    border: 2px solid #3498db;
    transform: scale(1.05);
    box-shadow: 0 8px 25px rgba(52, 152, 219, 0.3);
}

.agency-card h3 {
    color: #34495e;
    margin-bottom: 10px;
    font-size: 1.2rem;
}

.star-rating {
    margin: 20px 0;
}

.star-rating .star {
    cursor: pointer;
    font-size: 28px;
    color: #e0e0e0;
    transition: color 0.2s ease;
}

.star-rating .star.filled {
    color: #f1c40f;
}

.review-form {
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 2; /* Higher than the page, but lower than the navbar */
}

.review-form textarea {
    width: 100%;
    height: 150px;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

.review-form textarea:focus {
    outline: none;
    border-color: #3498db;
}

.review-form button {
    background-color: #3498db;
    color: white;
    padding: 12px 25px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.review-form button:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .review-page {
        padding: 30px 15px;
    }

    .review-page h1 {
        font-size: 2rem;
    }

    .agency-cards {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        gap: 20px;
    }

    .agency-card {
        padding: 20px;
    }

    .star-rating .star {
        font-size: 24px;
    }

    .review-form {
        padding: 20px;
    }

    .review-form textarea {
        height: 120px;
        font-size: 14px;
    }

    .review-form button {
        padding: 10px 20px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .review-page h1 {
        font-size: 1.75rem;
    }

    .agency-cards {
        grid-template-columns: 1fr;
    }

    .agency-card {
        padding: 15px;
    }

    .star-rating .star {
        font-size: 22px;
    }

    .review-form textarea {
        height: 100px;
    }

    .review-form button {
        width: 100%;
    }
}

/* ResetPassword.css */

/* Container for the entire component */
.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f9;
  padding: 20px;
}

/* Card containing the form */
.reset-password-card {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

/* Title styling */
.reset-password-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

/* Form styling */
.reset-password-form {
  display: flex;
  flex-direction: column;
}

/* Input field styling */
.reset-password-input {
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

/* Button styling */
.reset-password-button {
  padding: 12px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reset-password-button:hover {
  background-color: #0056b3;
}

/* Responsive styles */
@media (max-width: 768px) {
  .reset-password-card {
    padding: 20px;
  }

  .reset-password-title {
    font-size: 20px;
  }

  .reset-password-input,
  .reset-password-button {
    font-size: 14px;
  }
}

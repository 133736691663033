.promo-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.promo-popup-content {
  position: relative;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  padding: 0;
  max-width: 800px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  z-index: 1;
}

.close-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.promo-popup-content h3 {
  color: #2c3e50;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 20px;
  background-color: #f8f9fa;
  border-bottom: 2px solid #3498db;
  border-radius: 8px 8px 0 0;
}

.promo-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0;
}

.promo-table th,
.promo-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.promo-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  font-size: 0.9rem;
}

.promo-table tr {
  background-color: #ffffff;
}

.promo-table tr:last-child td {
  border-bottom: none;
}

.promo-table tr:hover {
  background-color: #f8f8f8;
}

@media (max-width: 600px) {
  .promo-popup-content {
    width: 95%;
  }

  .promo-popup-content h3 {
    font-size: 1.2rem;
    padding: 15px;
  }

  .promo-table {
    font-size: 14px;
  }

  .promo-table th,
  .promo-table td {
    padding: 10px;
  }

  .promo-table th {
    font-size: 0.8rem;
  }

  .close-button {
    font-size: 20px;
  }
}